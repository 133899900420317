<template>
  <v-data-table
    :headers="headers"
    :items="getPersonas"
    :search="search"
    single-expand
    sort-by="idpersona"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>{{ titulo }}</v-toolbar-title>

        <v-spacer></v-spacer>
        <!--BUSCADOR-->
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Buscar..."
          hide-details
        ></v-text-field>

        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="700px">
          <template v-slot:activator="{ on }">
            <v-btn
              color="primary"
              dark
              class="mb-2"
              v-on="on"
              >Crear Nuevo</v-btn
            >
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-form ref="miFormulario" lazy-validation v-model="formvalid">
                  <v-row>
                    <v-col cols="8">
                      <v-text-field
                        v-model="editedItem.nombre"
                        label="Nombre"
                        clearable
                        :rules="reglasNombre"
                        autofocus
                      ></v-text-field>
                    </v-col>
                    <v-col cols="4" sm="4" md="4">
                      <v-text-field
                        v-model="editedItem.dpi"
                        label="Número DPI"
                        :rules="reglasDpi"
                        clearable
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3" md="3">
                      <v-text-field
                        v-model="editedItem.nit"
                        label="Nit"
                        :rules="reglasNit"
                        clearable
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="9" md="9">
                      <v-text-field
                        v-model="editedItem.direccion"
                        label="Dirección"
                        clearable
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field
                        v-model="editedItem.institucion"
                        label="Institución"
                        clearable
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-select
                        v-model="editedItem.estado"
                        :items="estados"
                        label="Estado"
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-form>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">Cancelar</v-btn>
              <v-btn color="blue darken-1" text @click="save">Guardar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:[`item.idpersona`]="{ item }">
      {{ item.idpersona }}
    </template>
    <template v-slot:[`item.usuario`]="{ item }">
      {{ item.usuario | textLimit}}
    </template>
    <template v-slot:[`item.fechaentrega`]="{ item }">
      {{ item.fechaentrega | formatoFecha }}
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <v-btn small class="mx-1" icon color="orange" @click="navegar(item)">
        <v-icon>mdi-folder-account</v-icon>
      </v-btn>
      <v-btn small class="mx-1" icon color="indigo" @click="editItem(item)">
        <v-icon> mdi-pencil </v-icon>
      </v-btn>
      <v-btn small class="mx-1" icon color="red" @click="deleteItem(item)">
        <v-icon>mdi-delete</v-icon>
      </v-btn>
    </template>
    <template v-slot:no-data>
      <v-btn color="primary" @click="initialize"> Reset </v-btn>
    </template>
  </v-data-table>
</template>
<script>
import { mapGetters } from 'vuex';
import axios from 'axios';
import Swal from 'sweetalert2';

export default {
  data: () => ({
    titulo: 'Maestros',
    search:'',
    editeditem: '',
    dialog: false,
    headers: [
      { text: 'No.', value: 'idpersona',align: 'center'},
      { text: 'Nombre', value: 'nombre',width: '25%' },
      { text: 'Nit', value: 'nit' },
      { text: 'DPI', value: 'dpi' },
      { text: 'Fecha Entrega', value: 'fechaentrega' },
      { text: 'Direccion', value: 'direccion' },
      { text: 'Institución', value: 'institucion' },
      { text: 'Usuario', value: 'usuario'},
      { text: 'Estado', value: 'estado'},
      { text: 'Opciones', value: 'actions',width: '150px', sortable: false },
    ],
    formvalid: true,
    editedIndex: -1,
    editedItem: {
      nombre: '',
      nit: '',
      direccion: '',
      dpi: '',
      institucion: 'MINEDUC',
      estado: 'Pendiente',
    },
    defaultItem: {
      nombre: '',
      nit: '',
      direccion: '',
      dpi: '',
      institucion: 'MINEDUC',
      estado: 'Pendiente',
    },
    estados: ['Pendiente', 'En Proceso', 'Faltan Documentos', 'Finalizado'],
    reglasNit: [
      (v) => !!v || 'El campo no puede estar vacio',
      (v) => /^[0-9]+(-?[0-9kK])?$/.test(v) || 'El Nit es inválido',
    ],
    reglasNombre: [
      (v) => !!v || 'El campo no puede estar vacio',
      (v) => /^([a-z ñáéíóú']{2,60})$/i.test(v) || 'El Nombre es inválido',
    ],
    reglasDpi: [
      (v) => !!v || 'El campo no puede estar vacio',
      (v) => /^[0-9]{4}\s?[0-9]{5}\s?[0-9]{4}$/.test(v) || 'El CUI es inválido',
    ],
  }),

  computed: {
    ...mapGetters(['getPersonas', 'apiUrl']),
    formTitle() {
      return this.editedIndex === -1 ? 'Nuevo Registro' : 'Editar Registro';
    },
  },

  created() {
    localStorage.removeItem('idpersona');
    this.initialize();
  },

  methods: {
    initialize() {
      this.$store.dispatch('getAllPersonas');
    },
    editItem(item) {
      this.editedIndex = this.getPersonas.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.editeditem = item;
      this.dialog = true;
    },
    close() {
      this.$refs.miFormulario.reset();
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    async save() {
      if (this.editedIndex > -1) {
        await this.updateItem();
      } else {
        await this.saveItem();
      }
      this.close();
    },
    deleteItem(item) {
      Swal.fire({
        title: 'Está Seguro?',
        text: 'El registro séra Eliminado!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Confirmar!',
        cancelButtonText: 'Cancelar',
      }).then((result) => {
        if (result.isConfirmed) {
          this.deleteItemConfirm(item);
        }
      });
    },
    navegar(item){
         localStorage.setItem('idpersona',item.idpersona)
         this.$store.dispatch("setIdPersona");
         this.$router.push('/perfil');
    },
    //:::::::::::::::::::::::MIS METODOS HTTP DELETE:::::::::::::::::::::::
    async saveItem() {
      this.editedItem.nit = this.editedItem.nit.replace(/-/, '');

      try {
        const response = await axios.post(
          `${this.apiUrl}/personas`,
          this.editedItem,
        );
        Swal.fire({
          icon: 'success',
          title: 'Registro Creado',
          showConfirmButton: false,
          timer: 1500,
        });
        this.$store.dispatch('savePersona', response.data);
      } catch (error) {
        console.error(error);
        Swal.fire({ icon: 'error', title: 'Error al crear el Registro' });
      }
    },

    async updateItem() {
      this.editedItem.nit = this.editedItem.nit.replace(/-/, '');
      const id = this.editedItem.idpersona;
      try {
        const response = await axios.put( `${this.apiUrl}/personas/${id}`,this.editedItem,);
        Swal.fire({
          icon: 'success',
          title: 'Registro Modificado',
          showConfirmButton: false,
          timer: 1500,
        });
        this.$store.dispatch('updatePersona', response.data);
      } catch (error) {
        console.error(error);
        Swal.fire({ icon: 'error', title: 'Error al modificar el Registro' });
      }
    },
    async deleteItemConfirm(item) {
      item.estado = 'Eliminado';

      const id = item.idpersona;
      try {
        const response = await axios.put(`${this.apiUrl}/personas/${id}`, item);
        Swal.fire({
          icon: 'success',
          title: 'Registro Eliminado',
          showConfirmButton: false,
          timer: 1500,
        });
        this.$store.dispatch('deletePersona', response.data);
      } catch (error) {
        console.error(error);
        Swal.fire({ icon: 'error', title: 'Error al eliminar el Registro' });
      }
    },
  },
  filters:{
        formatoFecha: function (value) {
            if (!value) return 'Sin Definir'
            else return value
        },
        textLimit: function (value) {
            if (!value) return ''
            else return value.substring(0, 5)+'...';
        }
    }
};
</script>
