import Vue from 'vue'
import vuex from '../store/index'
import VueRouter from 'vue-router'
import Perfil from '@/views/Perfil.vue'
import Persona from '../components/persona/personaDataTable.vue'
import Login from '../components/login/Login.vue'
import Reportes from '@/views/Reportes.vue'
import Calendario from '@/views/Calendario.vue'
import Egresos from '@/views/Egresos.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name:'Persona',
    component: Persona,
    meta:{
      title:'Listado clientes'
    }
  },
  {
    path:'/perfil',
    name:'Perfil',
    component: Perfil,
    meta:{
      title:'Opciones del Cliente'
    }
  },
  {
    path:'/calendario',
    name:'Calendario',
    component: Calendario,
    meta:{
      title:'Calendario'
    }
  },
  {
    path:'/egresos',
    name:'Egresos',
    component: Egresos,
    meta:{
      title:'Registro de Egresos'
    }
  },
  {
    path:'/login',
    name:'Login',
    component: Login,
    meta:{
      title:'Autenticación'
    }
  },
  {
    path:'/reportes',
    name:'Reportes',
    component: Reportes,
    meta:{
      title:'Reporteria'
    }
  },
  {
    path: '*',
    name: '404',
    meta:{
      title:'Error! 404'
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/404.vue')
  },
  
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  let isAuthenticated = vuex.getters.getUsuario
  if (to.name !== 'Login' && !isAuthenticated) next({ name: 'Login' })
  else next()
})

const DEFAULT_TITLE = 'Declaraciones ASECONTABLES';
router.afterEach((to) => {
    document.title = to.meta.title || DEFAULT_TITLE;
});

export default router
