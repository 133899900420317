import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import 'sweetalert2/dist/sweetalert2.min.css';
import VueClipboard from 'vue-clipboard2'

//Poner en Español
import * as dayjs from 'dayjs'
import 'dayjs/locale/es'
import customParseFormat from 'dayjs/plugin/customParseFormat'
dayjs.extend(customParseFormat)
dayjs.locale('es')

Vue.config.productionTip = false
Vue.use(VueClipboard)
new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
