import axios from 'axios'
import Swal from 'sweetalert2'

export default {
    state:{
        pagodetalles:[],
    },
    mutations:{
        setPagoDetalles(state,data){ state.pagodetalles=data},

        deletePagoDetalle(state,id){
            const indice = state.pagodetalles.findIndex(elemento=>elemento.idpagodetalle  == id);
            state.pagodetalles.splice(indice, 1)
        },
        pushPagoDetalle(state,data){
             //Buscamos el indice por su id
             const indice = state.pagodetalles.findIndex(elemento=>elemento.idpagodetalle  == data.idpagodetalle );
             if (indice > -1){
                //Asignamos la data en el indice encontrado
                Object.assign(state.pagodetalles[indice], data)
             }else{
                 //Sino existe el indice insertamos el item
                 state.pagodetalles.push(data)
             }
             
        }
    },
    actions:{
        async getPagoDetalles({commit,getters},idpago){

            if(!idpago){
                idpago =  localStorage.getItem("idpago") 
            }
            try {

                const {data} = await axios.get(`${getters.apiUrl}/pagodetalles/${idpago}`,{headers:{'auth-user': getters.getToken}})
                commit("setPagoDetalles",data)
            } catch (error) {
                commit("setPagoDetalles",[])
                console.error(error)
            }
        },
        async postPagoDetalle({commit,getters},item){
            
            try {
                const {data}=await axios.post(`${getters.apiUrl}/pagodetalles`,item)
                commit("pushPagoDetalle",data)
                swalOk()
            } catch (error) {
                console.error(error)
                swalError('No pudo crearse el registro')
            }
        }, 
        async putPagoDetalle({commit,getters},item){
            try {
              const {data} = await axios.put(`${getters.apiUrl}/pagodetalles/${item.idpagodetalle }`,item)
              commit("pushPagoDetalle",data)
              swalOk()
            } catch (error) {
                console.error(error)
                swalError('No pudo actualizar el registro')
            }
        },
        async deletePagoDetalle({commit,getters},id){
            try {
                await axios.delete(`${getters.apiUrl}/pagodetalles/${id}`)
                commit("deletePagoDetalle",id)
                swalOk()
            } catch (error) {
                console.error(error)
                swalError('No pudo eliminarse el registro')
            }
        }
    },
    getters: {
        getPagoDetalles(state){
            return state.pagodetalles;
        }
    }
}

function swalOk(){
    Swal.fire({       
        icon: 'success',
        title: '¡Realizado!',
        showConfirmButton: false,
        timer: 1500
    })
}

function swalError(message){
    Swal.fire({       
        icon: 'error',
        title: 'Error',
        text:message
    })
}