<template>
  <v-sheet>
    <v-row>
      <v-col>
        <h2 class="mb-2 pl-4" style="color:#424242">REPORTES</h2>
       
        <v-card>
          <v-tabs
            v-model="tab"
            background-color="indigo"
            centered
            dark
            icons-and-text
          >
            <v-tabs-slider></v-tabs-slider>

            <v-tab href="#tab-1"> POR PAGOS </v-tab>

            <v-tab href="#tab-2"> POR TRAMITES </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item value="tab-1">
              <v-card flat>
                <ReportePagos />
              </v-card>
            </v-tab-item>
            <v-tab-item value="tab-2">
              <v-card flat>
                <ReporteDetallePagos />
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-col>
    </v-row>
  </v-sheet>
</template>
<script>
import ReportePagos from '@/components/reportes/ReportePagos.vue';
import ReporteDetallePagos from '@/components/reportes/ReporteDetallePagos.vue'

export default {
    components:{
        ReportePagos,ReporteDetallePagos
    },
    data: () => ({
        tab: null,
    })
}
</script>
