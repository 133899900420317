<template>
  <v-main>
         <v-container fluid fill-height>
            <v-layout align-center justify-center>
               <v-flex xs12 sm8 md4>
                  <v-card class="elevation-12">
                     <v-toolbar dark color="teal darken-3">
                        <v-toolbar-title> Ingresar</v-toolbar-title>
                     </v-toolbar>
                     <v-card-text>
                        <v-form>
                           <v-text-field
                              prepend-icon="mdi-account"
                              label="Usuario"
                              v-model="auth.Login"
                              type="text"
                              autofocus
                              required
                           ></v-text-field>
                           <v-text-field
                            v-model="auth.Clave"
                              prepend-icon="mdi-lock"
                              label="Contraseña"
                              type="password"
                              required
                           ></v-text-field>
                        </v-form>
                     </v-card-text>
                     <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn  color="primary" @click="ingresar">Ingresar</v-btn>
                     </v-card-actions>
                  </v-card>
               </v-flex>
            </v-layout>
         </v-container>
      </v-main>
</template>

<script>
export default {
  data: () => ({
    auth:{
        Login:'',
        Clave:''
    }
  }),
  methods:{
      ingresar(){
          this.$store.dispatch('loginUser',this.auth)
      }
  }
};
</script>