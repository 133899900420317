<template>
    <v-container>
        <v-row v-if="getPagos.length>0">
            <v-col class="d-flex justify-end">
                <v-btn color="teal" dark class="mb-2" @click="dialog = true;" >
                    CREAR COMPROBANTE
                    </v-btn>
            </v-col>
        </v-row>
        <v-expansion-panels v-model="panel">
            <v-expansion-panel expand
            v-for="(item,i) in getPagos"
            :key="i"
            >
            <v-expansion-panel-header color="light-green" class="white--text" @click="getPagoDetalles(item)" disable-icon-rotate>
                <v-row class="textcard">
                    <v-col>
                        <strong>Recibo: {{item.codigo}}</strong>  
                    </v-col>
                    <v-col cols="3">
                        <strong>Fecha Entrega: {{item.fechaentrega | formatoFecha}}</strong>  
                    </v-col>
                    <v-col cols="3">
                        <strong>Total a Pagar: Q {{item.total}}</strong>  
                    </v-col>
                    <v-col v-if="item.estado=='Pendiente'">
                        <strong>Anticipo: Q {{item.anticipo}}</strong>  
                    </v-col>
                    <v-col v-if="item.estado=='Pendiente'">
                        <v-chip color="orange" small text-color="white">Pendiente: Q {{item.total-item.anticipo}}</v-chip>  
                    </v-col>
                    <v-col v-if="item.estado=='Cancelado'">
                        <v-chip color="red" small text-color="white">CANCELADO</v-chip>  
                    </v-col>
                </v-row>
              <template v-slot:actions>
                  <v-btn icon> <v-icon color="light-green darken-4">mdi-printer</v-icon> </v-btn>
                  <v-btn icon @click="editItem(item)"> <v-icon color="light-green darken-4">mdi-pencil</v-icon> </v-btn>
                  <v-btn icon @click="deleteSwal(item)" > <v-icon color="light-green darken-4">mdi-trash-can</v-icon> </v-btn>
                  <v-divider vertical class="ml-2 mr-2" ></v-divider>
                  <v-btn icon> <v-icon color="light-green darken-4">mdi-menu-down</v-icon> </v-btn>
                    
                </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
                <PagoDetalle/>
            </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
        
        <v-card v-show="getPagos.length==0">
            <v-card-text class="text-h5 font-weight-bold text-center" >
                NO HAY COMPROBANTES CREADOS <br>
                <v-dialog
                v-model="dialog"
                max-width="500px"
                >
                <template v-slot:activator="{ on, attrs }">
                    <v-btn color="teal" dark class="mb-2" v-bind="attrs" v-on="on">
                    CREAR COMPROBANTE
                    </v-btn>
                </template>
                <v-card>
                    <v-card-title>
                    <span class="text-h5">{{formTitle}}</span>
                    </v-card-title>

                    <v-card-text>
                    <v-container>
                        <v-row>
                        <v-col cols="4">
                            <v-text-field v-model="editedItem.codigo"  color="teal" label="Codigo Comprobante" readonly></v-text-field>
                        </v-col>
                        <v-col cols="8">
                           <v-menu
                                v-model="menu"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="editedItem.fechaentrega"
                                    label="Fecha Entrega"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    autofocus
                                ></v-text-field>
                                </template>
                                <v-date-picker
                                v-model="editedItem.fechaentrega"
                                @input="menu = false"
                                no-title
                                locale="es-MX"
                                ></v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field v-model="editedItem.anticipo" prefix="Q" hint="Ejemplo: Q 35 " color="teal" label="Anticipo"></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field v-model="editedItem.total" prefix="Q" hint="Ejemplo: Q 100 " color="teal" label="Total a Pagar"></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-select
                                v-model="editedItem.estado"
                                :items="estados"
                                label="Estado"
                            ></v-select>
                        </v-col>
                        </v-row>
                    </v-container>
                    </v-card-text>

                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="red"
                        dark
                        @click="close"
                    >
                        Cancelar
                    </v-btn>
                    <v-btn
                        color="teal"
                        dark
                        @click="save"
                    >
                        Guardar
                    </v-btn>
                    </v-card-actions>
                </v-card>
                </v-dialog>
            </v-card-text>
            
        </v-card>
    </v-container>
</template>
<script>
import Swal from 'sweetalert2'
import dayjs from 'dayjs'
import PagoDetalle from '@/components/pagodetalle/PagoDetalle.vue'

export default {
    components:{
        PagoDetalle
    },
    data: () => ({
        dialog:false,
        panel:0,
        menu: false,
        estados:['Pendiente','Cancelado'],
        editedItem:{
            codigo: "ASE001",
            fechasolicitud: dayjs().format('YYYY-MM-DD'),
            fechaentrega: dayjs().format('YYYY-MM-DD'),
            anticipo: 0,
            total: 0,
            tipo: "INGRESO",
            estado: "Pendiente"
        },
        defaultItem:{
            codigo: "ASE001",
            fechasolicitud: dayjs().format('YYYY-MM-DD'),
            fechaentrega: dayjs().format('YYYY-MM-DD'),
            anticipo: 0,
            total: 0,
            tipo: "INGRESO",
            estado: "Pendiente"
        }
    }),
    async created(){
        //const rutaUrl = this.$route.name
          //  console.log('rutaUrl==>',rutaUrl)

        await this.$store.dispatch("getMediosPagos")
        await this.$store.dispatch("getCategorias")
        await this.$store.dispatch("getPagos")

         this.codigoTransaccion()
         if(this.getPagos[0]){
            await this.getPagoDetalles(this.getPagos[0])
         }
         
    }, 
    computed:{
        getPagos(){
        return this.$store.getters.getPagos;
        },
        formTitle() {
            return this.editedIndex === -1 ? 'Nuevo Registro' : 'Editar Registro';
        },
    },
     methods:{
        async getItems(){
                await this.$store.dispatch("getPagos");
        },
        async getPagoDetalles(item){
            localStorage.setItem("idpago",item.idpago)
            await this.$store.dispatch("getPagoDetalles",item.idpago);
        },
        async postItem(){
            
            
            await  this.$store.dispatch("postPago",this.editedItem);
        },
        async putItem(){
            await this.$store.dispatch("putPago",this.editedItem);
        },
        editItem(item) {   
            this.dialog = true; 
            this.editedIndex = this.getPagos.indexOf(item)       
            this.editedItem = Object.assign({}, item)
        },
        async deleteItem(id){
            await this.$store.dispatch("deletePago",id);
        },
        save () {
            this.parseNumber()
            if (this.editedIndex > -1) {
                 this.putItem();
            } else {
                this.postItem(); 
            }
            this.close()
        },
        async close(){  
            this.dialog = false;
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1
            })
        },
        deleteSwal(item){
            Swal.fire({
            title: 'Presione Sí para eliminar',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText:'Cancelar',
            confirmButtonText: 'Sí'
            }).then((result) => {
            if (result.isConfirmed) {
                this.deleteItem(item.idpago);
            }
            })
        },
        parseNumber(){
            this.editedItem.total = parseFloat(this.editedItem.total)
            this.editedItem.anticipo = parseFloat(this.editedItem.anticipo)
        },
        //GenerarCodigo
        codigoTransaccion() {
            let result = "";
            let characters =
                "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
            for (let i = 0; i < 6; i++) {
                result += characters.charAt(
                Math.floor(Math.random() * characters.length)
                );
            }
            this.editedItem.codigo = result;
        },
    },
    filters:{
        formatoFecha: function (value) {
            if (!value) return ''
            return dayjs(value).format('DD-MM-YYYY')
        }
    }
}
</script>
<style scoped>
    .textcard{
        color: #263238;
    }
</style>