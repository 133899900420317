import axios from 'axios'
import Swal from 'sweetalert2'

export default {
    state:{
        claves:[],
    },
    mutations:{
        setClaves(state,data){ state.claves=data},

        deleteClave(state,id){
            const indice = state.claves.findIndex(elemento=>elemento.idclave == id);
            state.claves.splice(indice, 1)
        },
        pushClaves(state,data){
             //Buscamos el indice por su id
             const indice = state.claves.findIndex(elemento=>elemento.idclave == data.idclave);
             if (indice > -1){
                //Asignamos la data en el indice encontrado
                Object.assign(state.claves[indice], data)
             }else{
                 //Sino existe el indice insertamos el item
                 state.claves.push(data)
             }
             
        }
    },
    actions:{
        async getClaves({commit,getters}){
            try {
                const {data} = await axios.get(`${getters.apiUrl}/claves/${getters.getIdPersona}`,{headers:{'auth-user': getters.getToken}})
                commit("setClaves",data)
            } catch (error) {
                commit("setClaves",[])
                console.error(error)
            }
        },
        async postClaves({commit,getters},item){
            item.idpersona = getters.getIdPersona;
            try {
                const {data}=await axios.post(`${getters.apiUrl}/claves`,item)
                commit("pushClaves",data)
                swalOk()
            } catch (error) {
                console.error(error)
                swalError('No pudo crearse el registro')
            }
        }, 
        async putClaves({commit,getters},item){
            try {
              const {data} = await axios.put(`${getters.apiUrl}/claves/${item.idclave}`,item)
              commit("pushClaves",data)
              swalOk()
            } catch (error) {
                console.error(error)
                swalError('No pudo actualizar el registro')
            }
        },
        async deleteClaves({commit,getters},id){
            try {
                await axios.delete(`${getters.apiUrl}/claves/${id}`)
                commit("deleteClave",id)
                swalOk()
            } catch (error) {
                console.error(error)
                swalError('No pudo eliminarse el registro')
            }
        }
    },
    getters: {
        getClaves(state){
            return state.claves;
        },
    }
}

function swalOk(){
    Swal.fire({       
        icon: 'success',
        title: '¡Realizado!',
        showConfirmButton: false,
        timer: 1500
    })
}

function swalError(message){
    Swal.fire({       
        icon: 'error',
        title: 'Error',
        text:message
    })
}