<template>
  <v-card>
    <v-data-table
      :headers="headers"
      :items="getCategorias"
      :search="search"
    >
    <template v-slot:top>
      <v-toolbar
        flat
      >
        <v-toolbar-title> <h3>{{$route.name=='Egresos'?'EGRESOS':'TRÁMITES'}} </h3> </v-toolbar-title>
        <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>
        <v-spacer></v-spacer>
        <v-dialog
          v-model="dialog"
          max-width="500px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="teal" dark class="mb-2" v-bind="attrs" v-on="on">
               <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">{{formTitle}}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="8">
                    <v-text-field v-model="editedItem.categoria" hint="Ejemplo: Honorarios" color="teal" label="Categoria"></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field v-model="editedItem.precio" hint="Ejemplo: Q 5.50" color="teal" label="Precio Trámite"></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6">
                    <v-select
                        v-model="editedItem.tipo"
                        :items="tipos"
                        label="Tipo"
                    ></v-select>
                  </v-col>
                  <v-col cols="6"></v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="red"
                dark
                @click="close"
              >
                Cancelar
              </v-btn>
              <v-btn
                color="teal"
                dark
                @click="save"
              >
                Guardar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
     <template v-slot:[`item.opciones`]="{ item }">
      <v-btn icon color="blue" @click="editItem(item)">
        <v-icon>mdi-lead-pencil</v-icon>
      </v-btn>
      <v-btn icon color="red" @click="deleteSwal(item)">
        <v-icon>mdi-trash-can</v-icon>
      </v-btn>
    </template>
    </v-data-table>
  </v-card>
</template>
<script>
import Swal from 'sweetalert2'

export default {
    data: () => ({
        dialog:false,
        search:'',
        tipos:['Ingreso','Egreso'],
        headers:[
            { text: 'Categoria', value: 'categoria' },
            { text: 'Precio', value: 'precio' },
            { text: 'Opciones', value: 'opciones' }
        ],
        editedIndex: -1,
        editedItem: {
            categoria:'',
            tipo:'Ingreso',
            precio:0.00
        },
        defaultItem: {
            categoria:'',
            tipo:'Ingreso',
            precio:0.00
        }
    }),
  created(){
      const id = parseInt(  localStorage.getItem('idpersona') ) 
      this.$store.dispatch("setIdPersona",id)
      this.$store.dispatch("getCategorias")
  }, 
    computed:{
        getCategorias(){
          const ruta = this.$route.name
    
          if(ruta==='Egresos'){
            return this.$store.getters.getCategoriasEgresos;
          }else{
            return this.$store.getters.getCategorias;
          }
        },
        formTitle() {
            return this.editedIndex === -1 ? 'Nuevo Registro' : 'Editar Registro';
        },
    },
    methods:{
      async getItems(){
            await this.$store.dispatch("getCategorias");
      },
      async postItem(){
        await  this.$store.dispatch("postCategoria",this.editedItem);
      },
      async putItem(){
          await this.$store.dispatch("putCategoria",this.editedItem);
      },
      editItem(item) {   
          this.dialog = true; 
          this.editedIndex = this.getCategorias.indexOf(item)       
          this.editedItem = Object.assign({}, item)
      },
      async deleteItem(id){
          await this.$store.dispatch("deleteCategoria",id);
      },
     save () {
          this.editedItem.precio = parseFloat(this.editedItem.precio)
          if (this.editedIndex > -1) {
                this.putItem();
          } else {
               this.postItem(); 
          }
          this.close()
     },
     async close(){  
         this.dialog = false;
         this.$nextTick(() => {
            this.editedItem = Object.assign({}, this.defaultItem);
            this.editedIndex = -1
          })
     },
     deleteSwal(item){
        Swal.fire({
        title: 'Presione Sí para eliminar',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText:'Cancelar',
        confirmButtonText: 'Sí'
        }).then((result) => {
        if (result.isConfirmed) {
            this.deleteItem(item.idpagocategoria );
        }
        })
    },
  }
}
</script>