<template>
    <v-sheet class="rounded-xl pa-5">
        
        <v-row>
            <v-col id="btnprint01">
                <v-data-table
                    dense
                    :headers="headers"
                    :items="itemslist"
                    :search="search"
                    sort-by="fecha"
                    class="elevation-1"
                >
                    <template v-slot:top>
                    <v-toolbar flat color="white">
                        <v-toolbar-title><h3 style="color:#424242">{{ title }}</h3></v-toolbar-title>
                        <v-divider class="mx-4" inset vertical></v-divider>
                        <v-text-field
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Buscar..."
                        single-line
                        color="teal"
                        hide-details
                        ></v-text-field>
                        <v-spacer></v-spacer>
                        
                    </v-toolbar>
                    </template>
                    <template v-slot:[`item.usuario`]="{ item }">
                    {{item.usuario | textLimit}}
                    </template>
                    <template v-slot:no-data>
                    <h1>No se encontrarón datos en estas fechas</h1>
                    <h5>Seleccione otro rango de fechas</h5>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
    </v-sheet>
</template>
<script>
import axios from "axios";
import { mapGetters } from 'vuex'
export default {
  data: () => ({
    title: "REPORTE POR RECIBO",
    search: "",
    menu: false,
    menu2: false,
    modalfechafin: false,
    headers: [
      { text: "Codigo", value: "codigo" },
      { text: "Nombre", align: "left", value: "nombre" },
      { text: "F. Solicitud", value: "fechasolicitud",width: '10%' },
      { text: "F. Entrega", value: "fechaentrega",width: '10%' },
      { text: "Anticipo", align: "center", value: "anticipo" },
      { text: "Total", align: "center", value: "total"},
      { text: "Tipo", align: "center", value: "tipo"},
      { text: "Usuario", align: "center", value: "usuario"},
      { text: "Tramite", align: "center", value: "estadotramite"},
      { text: "Recibo", align: "center", value: "estadopago"}
    ],
    itemslist: [],
  }),

  created() {
    this.initialize();
  },
  computed:{
    ...mapGetters(["apiUrl"])
  },
  methods: {
    async initialize() {
      
      try {
         const {data}=await axios.get(`${this.apiUrl}/pagos`)
         this.itemslist = data
      } catch (error) {
        console.error(error)
      }

       
    },

    printPDF() {
      //Ocultamos el Boton de Imprimir encabezados
      document.getElementById("btnprint01").style.visibility="hidden"
      
      window.print()
      //Mostramos el Boton DESPUES de Imprimir
      document.getElementById("btnprint01").style.visibility="visible"
    },
    
  },
  filters:{
        textLimit: function (value) {
            if (!value) return ''
            else return value.substring(0, 5)+'...';
        }
    }
};
</script>