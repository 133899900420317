<template>
  <v-sheet>
    <v-row>
      <v-col>
        <h2 class="mb-2 pl-4" style="color:#424242">Registro de Egresos</h2>
       
        <v-card>
          <v-tabs
            v-model="tab"
            background-color="deep-purple accent-4"
            centered
            dark
            icons-and-text
          >
            <v-tabs-slider></v-tabs-slider>

            <v-tab href="#tab-1"> COMPROBANTES </v-tab>

            <v-tab href="#tab-2"> EGRESOS </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item value="tab-1">
              <v-card flat>
                <Egresos />
              </v-card>
            </v-tab-item>
            <v-tab-item value="tab-2">
              <v-card flat>
                <PagoCategorias />
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-col>
    </v-row>
  </v-sheet>
</template>
<script>
import PagoCategorias from '@/components/categorias/Categorias.vue';
import Egresos from '@/components/egresos/Egresos.vue'

export default {
    components:{
        PagoCategorias,Egresos
    },
    data: () => ({
        tab: null,
    })
}
</script>
