<template>
<v-container style="background:#ffff">
    <v-row>
        <v-col cols="12" >
            <v-btn class="mt-10" color="red" elevation="2" fab
            @click="$router.go(-1)"
            absolute  right top dark>
            <v-icon>mdi-window-close</v-icon>
            </v-btn>
            <v-alert
            icon="mdi-account-circle"
            prominent
            text
            type="info"
            >
            <v-row>
                
                <v-col cols="5"><strong>Nombre:</strong>{{persona.nombre}}</v-col>
                <v-col> <strong>Nit:</strong>  {{persona.nit}} </v-col>
                <v-col> <strong>Institución:</strong>  {{persona.institucion}} </v-col>
                <v-col v-if="persona.dpi">  <strong>DPI:</strong>  {{persona.dpi}} </v-col>
            </v-row>
            </v-alert>
        </v-col>
        <v-col cols="7">
            <Anotaciones/>
        </v-col>
        <v-col cols="5">
            <Contacto/>
        </v-col>
    </v-row>
    <v-row>
        <v-col>
            <Claves/>
        </v-col>
    </v-row>
    <v-row>
        <v-col>
            <v-card>
                <v-tabs
                v-model="tab"
                background-color="deep-purple accent-4"
                centered
                dark
                icons-and-text
                >
                <v-tabs-slider></v-tabs-slider>

                <v-tab href="#tab-1">
                    COMPROBANTES
                </v-tab>

                <v-tab href="#tab-2">
                    TRÁMITES
                </v-tab>

                <v-tab href="#tab-3">
                    MEDIOS DE PAGO
                </v-tab>
                </v-tabs>
                <v-tabs-items v-model="tab">
                    <v-tab-item value="tab-1">
                        <v-card flat>
                            <Pago/>
                        </v-card>
                    </v-tab-item>
                    <v-tab-item value="tab-2">
                        <v-card flat>
                            <PagoCategorias/>
                        </v-card>
                    </v-tab-item>
                    <v-tab-item value="tab-3">
                        <v-card flat>
                            <PagoMedio/>
                        </v-card>
                    </v-tab-item>
                </v-tabs-items>
            </v-card>
        </v-col>
    </v-row>
</v-container>
    
</template>
<script>
import axios from 'axios'
import Claves from '@/components/claves/Claves.vue';
import Contacto from '@/components/contacto/Contacto.vue';
import Anotaciones from '@/components/anotaciones/Anotaciones.vue';
import PagoCategorias from '@/components/categorias/Categorias.vue';
import PagoMedio from '@/components/mediospago/mediopago.vue'
import Pago from '@/components/pagos/Pagos.vue'
export default {
    components:{
        Claves,Contacto,Anotaciones,PagoCategorias,PagoMedio,Pago
    },
    data: () => ({
        persona:'',
        tab: null,
    }),
    async created(){
       await this.inicializar()
    },
    methods:{
       async inicializar(){
            const id = parseInt(  localStorage.getItem('idpersona') ) 
           try {
               const {data} = await axios.get(`${this.$store.getters.apiUrl}/personas/${id}`)
               this.persona = data[0]
           } catch (error) {
               console.log(error)
           }
        }
    }    
}
</script>