<template>
  <v-card>
    <v-data-table
      :headers="headers"
      :items="getAnotaciones"
      :search="search"
    >
    <template v-slot:top>
      <v-toolbar
        flat
      >
        <v-toolbar-title> <h3>{{title}}</h3> </v-toolbar-title>
        <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>
        <v-spacer></v-spacer>
        <v-dialog
          v-model="dialog"
          max-width="500px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="teal" dark class="mb-2" v-bind="attrs" v-on="on">
              <v-icon>mdi-comment-plus</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">{{formTitle}}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-text-field v-model="editedItem.anotacion" hint="Anotacion" color="teal" label="Tipo"></v-text-field>
                  </v-col>
                  <v-col>
                    <v-select
                      v-model="editedItem.estado"
                      item-text="prioridad"
                      item-value="estado"
                      :items="estados"
                      label="Seleccione Prioridad"
                    ></v-select>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="red"
                dark
                @click="close"
              >
                Cancelar
              </v-btn>
              <v-btn
                color="teal"
                dark
                @click="save"
              >
                Guardar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:[`item.estado`]="{ item }">
      <v-chip :color="getColor(item.estado)" v-if="item.estado>1" dark>{{estados[item.estado-1].prioridad}}</v-chip>
      <strong v-else>{{estados[item.estado-1].prioridad}}</strong>
    </template>
     <template v-slot:[`item.opciones`]="{ item }">
      <v-btn icon color="blue" @click="editItem(item)">
        <v-icon>mdi-lead-pencil</v-icon>
      </v-btn>
      <v-btn icon color="red" @click="deleteSwal(item)">
        <v-icon>mdi-trash-can</v-icon>
      </v-btn>
    </template>
    </v-data-table>
  </v-card>
</template>
<script>
import Swal from 'sweetalert2'

export default {
    data: () => ({
        dialog:false,
        search:'',
        title:'ANOTACIONES',
        estados:[
          {prioridad:'Normal',estado:1},
          {prioridad:'Medio',estado:2},
          {prioridad:'Importante',estado:3},
          {prioridad:'Urgente',estado:4},
          {prioridad:'Realizado',estado:5},
          ],
        headers:[
            { text: 'Anotacion', value: 'anotacion' },
            { text: 'Prioridad', value: 'estado' },
            { text: 'Opciones', value: 'opciones' }
        ],
        editedIndex: -1,
        editedItem: { anotacion:'',estado:1},
        defaultItem: {anotacion:'',estado:1}
    }),
  created(){
      const id = parseInt(  localStorage.getItem('idpersona') ) 
      this.$store.dispatch("setIdPersona",id)
      this.$store.dispatch("getAnotaciones")
  }, 
    computed:{
        getAnotaciones(){
        return this.$store.getters.getAnotaciones;
        },
        formTitle() {
            return this.editedIndex === -1 ? 'Nuevo Registro' : 'Editar Registro';
        },
    },
  methods:{
      async getItems(){
            await this.$store.dispatch("getAnotaciones");
      },
      async postItem(){
        
        await  this.$store.dispatch("postAnotacion",this.editedItem);
      },
      async putItem(){
          await this.$store.dispatch("putAnotacion",this.editedItem);
      },
      editItem(item) {   
          this.dialog = true; 
          this.editedIndex = this.getAnotaciones.indexOf(item)       
          this.editedItem = Object.assign({}, item)
      },
      async deleteItem(id){
          await this.$store.dispatch("deleteAnotacion",id);
      },
     save () {

          if (this.editedIndex > -1) {
                this.putItem();
          } else {
               this.postItem(); 
          }
          this.close()
     },
     async close(){  
         this.dialog = false;
         this.$nextTick(() => {
            this.editedItem = Object.assign({}, this.defaultItem);
            this.editedIndex = -1
          })
     },
     deleteSwal(item){
        Swal.fire({
        title: 'Presione Sí para eliminar',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText:'Cancelar',
        confirmButtonText: 'Sí'
        }).then((result) => {
        if (result.isConfirmed) {
            this.deleteItem(item.idanotacion);
        }
        })
    },
    getColor (estado) {
        if (estado==5) return 'green'
        else if (estado== 4) return 'red'
        else if (estado== 3) return 'orange darken-3'
        else return 'amber'
    },
  },
}
</script>