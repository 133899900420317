var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.getAnotaciones,"search":_vm.search},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_c('h3',[_vm._v(_vm._s(_vm.title))])]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2",attrs:{"color":"teal","dark":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-comment-plus")])],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.formTitle))])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"hint":"Anotacion","color":"teal","label":"Tipo"},model:{value:(_vm.editedItem.anotacion),callback:function ($$v) {_vm.$set(_vm.editedItem, "anotacion", $$v)},expression:"editedItem.anotacion"}})],1),_c('v-col',[_c('v-select',{attrs:{"item-text":"prioridad","item-value":"estado","items":_vm.estados,"label":"Seleccione Prioridad"},model:{value:(_vm.editedItem.estado),callback:function ($$v) {_vm.$set(_vm.editedItem, "estado", $$v)},expression:"editedItem.estado"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"red","dark":""},on:{"click":_vm.close}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"color":"teal","dark":""},on:{"click":_vm.save}},[_vm._v(" Guardar ")])],1)],1)],1)],1)]},proxy:true},{key:"item.estado",fn:function(ref){
var item = ref.item;
return [(item.estado>1)?_c('v-chip',{attrs:{"color":_vm.getColor(item.estado),"dark":""}},[_vm._v(_vm._s(_vm.estados[item.estado-1].prioridad))]):_c('strong',[_vm._v(_vm._s(_vm.estados[item.estado-1].prioridad))])]}},{key:"item.opciones",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","color":"blue"},on:{"click":function($event){return _vm.editItem(item)}}},[_c('v-icon',[_vm._v("mdi-lead-pencil")])],1),_c('v-btn',{attrs:{"icon":"","color":"red"},on:{"click":function($event){return _vm.deleteSwal(item)}}},[_c('v-icon',[_vm._v("mdi-trash-can")])],1)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }