<template>
  <v-app id="inspire">
    <v-app-bar app color="teal" flat dark>
      <v-container class="py-0 fill-height" v-if="getUsuario">
        <!-- <v-avatar class="mr-10" color="grey darken-1" to="/" size="32">
        <img src="https://picsum.photos/510/300?random">
        </v-avatar> -->
          
          <div v-for="item in menu" :key="item.nombre" >
            
            <v-btn  text :to="item.path" v-if="item.rol=='Register'">
              <v-icon dark>{{item.icon}}</v-icon>
             {{ item.nombre }}
            </v-btn>
            <v-btn  text :to="item.path"  v-if="getUsuario.user.rol=='Admin' && item.rol=='Admin'">
              <v-icon dark>{{item.icon}}</v-icon>
             {{ item.nombre }}
            </v-btn>
          </div>

          <v-spacer></v-spacer>

          <v-responsive max-width="260">
            <v-btn outlined @click="logOut">
              <v-icon>mdi-account-arrow-left</v-icon>
              Cerrar Sesión
            </v-btn>
          </v-responsive>
       
        
      </v-container>
    </v-app-bar>
  <v-main class="grey lighten-3">
      <v-container>
  <router-view></router-view>
      </v-container>
  </v-main>
    
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'App',

  data: () => ({
    menu:[
      {nombre:'Inicio',path:'/',rol:'Register',icon:'mdi-book-account'},
      {nombre:'Calendario',path:'/calendario',rol:'Register',icon:'mdi-calendar-month'},
      {nombre:'Reportes',path:'/reportes',rol:'Admin',icon:'mdi-file-chart'},
      {nombre:'Egresos',path:'/egresos',rol:'Admin',icon:'mdi-cash-minus'}
    ],
  }),
  computed:{
    ...mapGetters(["getUsuario"])
  },
  methods:{
    logOut(){
      this.$store.dispatch('logoutUser',false)
      this.$router.push('/login')
    }
  }
};
</script>
