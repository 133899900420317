var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Listado de Declaraciones "),_c('v-spacer'),_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Fecha Entrega","prepend-icon":"mdi-calendar","readonly":"","clearable":"","autofocus":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"no-title":"","locale":"es-MX"},on:{"input":function($event){_vm.menu = false}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.listado,"search":_vm.search},scopedSlots:_vm._u([{key:"item.fechaentrega",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatoFecha")(item.fechaentrega))+" ")]}},{key:"item.cantidad",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getColor(item.cantidad),"dark":""}},[_vm._v(" "+_vm._s(item.cantidad)+" ")])]}}],null,true)})],1)],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-card',[_c('v-row',{staticClass:"pt-5",attrs:{"justify":"center"}},[_c('v-date-picker',{attrs:{"min":"2021-12-01","locale":"es-MX"},model:{value:(_vm.picker),callback:function ($$v) {_vm.picker=$$v},expression:"picker"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }