<template>
    <v-container>
        <v-simple-table>
            <template v-slot:default>
            <thead>
                <tr>
                <th class="text-left"> Cantidad </th>
                <th class="text-left">{{$route.name=='Egresos'?'Egreso':'Trámite'}} </th>
                <th class="text-left">Forma Pago </th>
                <th class="text-left">Precio </th>
                <th class="text-left">Subtotal </th>
                <th class="text-left">Opciones</th>
                </tr>
            </thead>
            <tbody>
                <tr
                v-for="item in getPagoDetalles"
                :key="item.idpagodetalle"
                >
                <td>{{ item.cantidad }}</td>
                <td>{{ item.categoria }} {{item.descripcion}}</td>
                <td>{{ item.nombre }}</td>
                <td>Q {{ item.precio }}</td>
                <td>Q {{ item.precio * item.cantidad}}</td>
                <td>
                    <v-btn icon color="blue" @click="editItem(item)">
                        <v-icon>mdi-lead-pencil</v-icon>
                    </v-btn>
                    <v-btn icon color="red" @click="deleteSwal(item)">
                        <v-icon>mdi-trash-can</v-icon>
                    </v-btn>
                </td>
                </tr>

                <tr >
                   <td colspan="6" align="center">
                        <v-btn outlined color="teal" @click="dialog=true">
                            <v-icon>mdi-plus</v-icon> Agregar
                        </v-btn>
                   </td>
                        
                    
                </tr>
            </tbody>
            </template>
        </v-simple-table>
        <v-dialog v-model="dialog" max-width="700">
            <v-card>
                    <v-card-title>
                    <span class="text-h5">{{formTitle}}</span>
                    </v-card-title>
                    <v-card-text>
                    <v-container>
                        <v-row>
                        <v-col cols="12">
                           <v-autocomplete
                                v-model="editedItem.idcategoriapago"
                                :items="this.$route.name=='Egresos'?getCategoriasEgresos:getCategorias"
                                item-text="categoria"
                                item-value="idpagocategoria"
                                hide-selected
                                :label="this.$route.name=='Egresos'?'Egreso':'Trámite'"
                                @change="setPrecio()"
                            ></v-autocomplete>
                        </v-col>
                        <v-col cols="4">
                            <v-text-field v-model="editedItem.cantidad"  color="teal" label="Cantidad"></v-text-field>
                        </v-col>
                        <v-col cols="4">
                            <v-text-field v-model="editedItem.precio" prefix="Q" color="teal" label="Precio"></v-text-field>
                        </v-col>
                        <v-col cols="4">
                            <v-text-field prefix="Q"  color="teal" label="Total" :value="editedItem.cantidad*editedItem.precio" disabled></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field v-model="editedItem.descripcion" color="teal" label="Descripción"></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-autocomplete
                                v-model="editedItem.idmediopago"
                                :items="getMediosPagos"
                                item-text="nombre"
                                item-value="idpagomedio"
                                hide-selected
                                label="Forma de Pago"
                            ></v-autocomplete>
                        </v-col>
                        </v-row>
                    </v-container>
                    </v-card-text>

                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="red"  dark  @click="close">
                        Cancelar
                    </v-btn>
                    <v-btn  color="teal"  dark @click="save" >
                        Guardar
                    </v-btn>
                    </v-card-actions>
                </v-card>
        </v-dialog>
    </v-container>
</template>
<script>
import Swal from 'sweetalert2'

export default {
    data: () => ({
        dialog:false,
        editedIndex: -1,
        editedItem: {
            cantidad: 1,
            precio: 0,
            descripcion: "",
            idpago: 1,
            idmediopago: 1,
            idcategoriapago: 0
        },
        defaultItem: {
            cantidad: 1,
            precio: 0,
            descripcion: "",
            idpago: 1,
            idmediopago: 1,
            idcategoriapago: 0
        }
    }),
    computed:{
        getPagoDetalles(){
        return this.$store.getters.getPagoDetalles;
        },
        getCategorias(){
        return this.$store.getters.getCategorias;
        },
        getCategoriasEgresos(){
        return this.$store.getters.getCategoriasEgresos;
        },
        getMediosPagos(){
        return this.$store.getters.getMediosPagos;
        },
        formTitle() {
            return this.editedIndex === -1 ? 'Nuevo Registro' : 'Editar Registro';
        },
    },
    methods:{
      async getItems(){
            await this.$store.dispatch("getPagoDetalles");
      },
      setPrecio(){
            const buscarEn =  this.$route.name=='Egresos'?this.getCategoriasEgresos:this.getCategorias
          const tramite = buscarEn.find(elemento=>elemento.idpagocategoria==this.editedItem.idcategoriapago)
          this.editedItem.precio = parseFloat(tramite.precio).toFixed(2)
      },
      async postItem(){
        
        await  this.$store.dispatch("postPagoDetalle",this.editedItem);
      },
      async putItem(){
          await this.$store.dispatch("putPagoDetalle",this.editedItem);
      },
      editItem(item) {   
          this.dialog = true; 
          this.editedIndex = this.getPagoDetalles.indexOf(item)       
          this.editedItem = Object.assign({}, item)
      },
      async deleteItem(id){
          await this.$store.dispatch("deletePagoDetalle",id);
      },
     async save () {
            this.parseNumber()
          if (this.editedIndex > -1) {
              await  this.putItem();
          } else {
              await this.postItem(); 
          }
          this.close()
          await this.$store.dispatch("getPagoDetalles");

          await this.sumarPagoDetalle()
     },
     async close(){  
         this.dialog = false;
         this.$nextTick(() => {
            this.editedItem = Object.assign({}, this.defaultItem);
            this.editedIndex = -1
          })
     },
     deleteSwal(item){
        Swal.fire({
        title: 'Presione Sí para eliminar',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText:'Cancelar',
        confirmButtonText: 'Sí'
        }).then((result) => {
        if (result.isConfirmed) {
            this.deleteItem(item.idpagodetalle);
        }
        })
    },
    parseNumber(){
            const idpago =  localStorage.getItem("idpago") 
            this.editedItem.precio = parseFloat(this.editedItem.precio).toFixed(2)
            this.editedItem.cantidad = parseFloat(this.editedItem.cantidad).toFixed(2)
            this.editedItem.idmediopago = parseInt(this.editedItem.idmediopago)
            this.editedItem.idcategoriapago = parseInt(this.editedItem.idcategoriapago) 
            this.editedItem.idpago = parseInt(idpago)
    },
   async sumarPagoDetalle(){

        const idpago =  localStorage.getItem("idpago") 

        const sumatoria = this.getPagoDetalles.reduce(
            (acumulado,sig)=>acumulado + sig.cantidad * sig.precio, 0
        )

        await this.$store.dispatch("putPago",{total:sumatoria,idpago});
    },
    
  }
}
</script>