<template>
    <v-sheet class="rounded-xl pa-5">
        
        <v-row>
            <v-col>
              <!-- <v-alert  color="blue-grey" dark dense icon="mdi-information-outline" prominent>
                -Si el comprobante esta pendiente aparece la fecha que se recibio la solicitud. <br>
                -Si el comprobante esta Cancelado aparece la fecha de entrega.
              </v-alert> -->
                <v-data-table
                    dense
                    :headers="headers"
                    :items="itemslist"
                    :search="search"
                    sort-by="nombre"
                    class="elevation-1"
                >
                    <template v-slot:top>
                    <v-toolbar flat color="white">
                        <v-toolbar-title><h3 style="color:#424242">{{ title }}</h3></v-toolbar-title>
                        <v-divider class="mx-4" inset vertical></v-divider>
                        <v-text-field
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Buscar..."
                        single-line
                        color="teal"
                        hide-details
                        ></v-text-field>
                        <v-spacer></v-spacer>                      
                    </v-toolbar>
                    </template>
                    <template v-slot:[`item.nombre`]="{ item }">
                    {{item.nombre }} - {{item.descripcion}}
                    </template>
                    <template v-slot:[`item.reporte`]="{ item }">
                    <p v-if="item.estado=='Cancelado'">{{item.total-item.anticipo}}</p>
                    <p v-else>{{item.anticipo}}</p>
                    </template>
                    <template v-slot:no-data>
                    <h1>No se encontrarón datos en estas fechas</h1>
                    <h5>Seleccione otro rango de fechas</h5>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
    </v-sheet>
</template>
<script>
import axios from "axios";
import Swal from "sweetalert2";
import dayjs from 'dayjs';
import { mapGetters } from 'vuex'
export default {
  data: () => ({
    title: "REPORTE POR TRAMITES",
    search: "",
    menu: false,
    menu2: false,
    modalfechafin: false,
    fechas:{
        fechainicio:dayjs().format('YYYY-MM-DD'),
        fechafin: dayjs().format('YYYY-MM-DD'),
    },
    headers: [
      { text: "Nombre", value: "nombre" },
      { text: "Total", align: "center", value: "reporte" },
      { text: "F. Solicitud", value: "fechasolicitud",width: '10%' },
      { text: "F. Entrega", value: "fechaentrega",width: '10%' },
      { text: "Tipo", align: "center", value: "tipo"},
      { text: "E. Recibo", align: "center", value: "estado"}
    ],
    itemslist: [],
  }),

  created() {
    this.initialize();
  },
  computed:{
    ...mapGetters(["apiUrl"])
  },
  methods: {
    validarfechas() {
      this.modal = false;
      this.modalfechafin = false;
      if (this.fechas.fechafin < this.fechas.fechainicio) {
        Swal.fire("Error!! Fecha Fin no puede ser menor a fecha inicio");
        Swal.fire({
          icon: "error",
          title: "Error rango de fechas",
          text: "Error!! Fecha Fin no puede ser menor a fecha inicio",
          showConfirmButton: false,
          timer: 1500,
        });
        this.$refs.fechafin.focus();
      } else {
        this.initialize();
      }
    },
    async initialize() {
      
      try {
         const {data}=await axios.get(`${this.apiUrl}/pagodetalles`)
         this.itemslist = data
      } catch (error) {
        console.error(error)
      }

       
    },

    printPDF() {
      //Ocultamos el Boton de Imprimir encabezados
      document.getElementById("btnprint01").style.visibility="hidden"
      
      window.print()
      //Mostramos el Boton DESPUES de Imprimir
      document.getElementById("btnprint01").style.visibility="visible"
    },
    //Notificaciones Swal
    alertDone(message) {
      Swal.fire({
        icon: "success",
        title: message,
        showConfirmButton: false,
        timer: 1500,
      });
    },
    errorSwal(message) {
      Swal.fire({
        icon: "error",
        title: message,
      });
    },
  },
};
</script>