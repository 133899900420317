import axios from 'axios'
import Swal from 'sweetalert2'
import router from '@/router'

export default {
    state:{
        usuario:false,
    },
    mutations:{
        setUsuario(state,data){ state.usuario=data},
    },
    actions:{
        async loginUser({commit},item){
            const urlLogin = 'https://asecontables.microsystems.com.gt/api/v1'
            try {
                const {data}=await axios.post(`${urlLogin}/users/login`,item)
                commit("setUsuario",data)
                //swalOk()
                router.push('/calendario')
            } catch (error) {
                console.error(error)
                swalError('No pudo crearse el registro')
            }
        },
        logoutUser({commit},item){
            commit("setUsuario",item)
        }
    },
    getters: {
        getUsuario(state){
            return state.usuario;
        },
    }
}

// function swalOk(){
//     Swal.fire({       
//         icon: 'success',
//         title: '¡Realizado!',
//         showConfirmButton: false,
//         timer: 1500
//     })
// }

function swalError(message){
    Swal.fire({       
        icon: 'error',
        title: 'Error',
        text:message
    })
}