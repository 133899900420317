<template>
    <v-row>
      <v-col cols="8">
        <v-card>
            <v-card-title>
              Listado de Declaraciones
              <v-spacer></v-spacer>
              <v-menu
                    v-model="menu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                    <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="search"
                        label="Fecha Entrega"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        clearable
                        autofocus
                    ></v-text-field>
                    </template>
                    <v-date-picker
                    v-model="search"
                    @input="menu = false"
                    no-title
                    locale="es-MX"
                    ></v-date-picker>
                </v-menu>
            </v-card-title>
            <v-data-table
              :headers="headers"
              :items="listado"
              :search="search"
            >
            <template v-slot:[`item.fechaentrega`]="{ item }">
                {{ item.fechaentrega | formatoFecha}}
            </template>
            <template v-slot:[`item.cantidad`]="{ item }">
              <v-chip
                :color="getColor(item.cantidad)"
                dark
              >
                {{ item.cantidad }}
              </v-chip>
            </template>
            </v-data-table>
          </v-card>
      </v-col>
      <v-col cols="4">
        <v-card>
          <v-row justify="center" class="pt-5">
            <v-date-picker 
            min="2021-12-01"
            locale="es-MX"
            v-model="picker"></v-date-picker>
          </v-row>
        </v-card>
      </v-col>
    </v-row>


</template>
<script>
import axios from 'axios'
import * as dayjs from 'dayjs'


export default {
    data: () => ({
        menu:false,
        picker:(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        search:'',
        headers:[
            { text: 'Fecha Entrega', value: 'fechaentrega' },
            { text: 'Cantidad', value: 'cantidad' }
        ],
        listado:[]
    }),
    created(){
        this.countByDay()
    },
    methods: {
      async countByDay(){
            
            try {
                const {data}=await axios.get(`${this.$store.getters.apiUrl}/pagos/countbyday`)
                //if(!data[0]) this.listado = []
                this.listado = data
            } catch (error) {
                console.error(error)
            }
        }, 
      getColor(cantidad){
          if(cantidad<10)return 'green'
          else if(cantidad>10 || cantidad<21 ) return 'orange darken-4'
          else if(cantidad>21 ) return 'red'
      }  
    },
    filters:{
        formatoFecha: function (value) {
            if (!value) return ''
            const mes = dayjs(value,'YYYY-MM-DD').format('MMMM').toUpperCase()
            const nombredia = dayjs(value,'YYYY-MM-DD').format('dddd').toUpperCase()
            const dia = dayjs(value,'YYYY-MM-DD').format('DD')
            return nombredia+' '+dia+' de '+mes
        }
    }
}
</script>