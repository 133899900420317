import axios from 'axios'
import Swal from 'sweetalert2'

export default {
    state:{
        pagos:[],
    },
    mutations:{
        setPagos(state,data){ state.pagos=data},

        deletePago(state,id){
            const indice = state.pagos.findIndex(elemento=>elemento.idpago  == id);
            state.pagos.splice(indice, 1)
        },
        pushPago(state,data){
             //Buscamos el indice por su id
             const indice = state.pagos.findIndex(elemento=>elemento.idpago  == data.idpago );
             if (indice > -1){
                //Asignamos la data en el indice encontrado
                Object.assign(state.pagos[indice], data)
             }else{
                 //Sino existe el indice insertamos el item
                 state.pagos.push(data)
             }
             
        }
    },
    actions:{
        async getPagos({commit,getters}){
            try {
                const {data} = await axios.get(`${getters.apiUrl}/pagos/${getters.getIdPersona}`)
                commit("setPagos",data)
            } catch (error) {
                commit("setPagos",[])
                console.error(error)
            }
        },
        async getEgresos({commit,getters}){
            try {
                const {data} = await axios.get(`${getters.apiUrl}/pagos/egresos`)
                commit("setPagos",data)
            } catch (error) {
                commit("setPagos",[])
                console.error(error)
            }
        },
        async postPago({commit,getters,dispatch},item){
            item.idpersona = getters.getIdPersona || 0;
            item.usuario = getters.getUsuario.user.Nombre;
            try {
                const {data}=await axios.post(`${getters.apiUrl}/pagos`,item)
                await dispatch('getPagoDetalles',data.idpago)
                localStorage.setItem("idpago",data.idpago)
                commit("pushPago",data)
                swalOk()
            } catch (error) {
                console.error(error)
                swalError('No pudo crearse el registro')
            }
        }, 
        async putPago({commit,getters},item){
            try {
              const {data} = await axios.put(`${getters.apiUrl}/pagos/${item.idpago }`,item)
              commit("pushPago",data)
              swalOk()
            } catch (error) {
                console.error(error)
                swalError('No pudo actualizar el registro')
            }
        },
        async deletePago({commit,getters},id){
            try {
                await axios.delete(`${getters.apiUrl}/pagos/${id}`)
                commit("deletePago",id)
                swalOk()
            } catch (error) {
                console.error(error)
                swalError('No pudo eliminarse el registro')
            }
        }
    },
    getters: {
        getPagos(state){
            return state.pagos;
        },
    }
}

function swalOk(){
    Swal.fire({       
        icon: 'success',
        title: '¡Realizado!',
        showConfirmButton: false,
        timer: 1500
    })
}

function swalError(message){
    Swal.fire({       
        icon: 'error',
        title: 'Error',
        text:message
    })
}