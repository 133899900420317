import axios from 'axios'
import Swal from 'sweetalert2'

export default {
    state:{
        categorias:[],
    },
    mutations:{
        setCategorias(state,data){ state.categorias=data},

        deleteCategoria(state,id){
            const indice = state.categorias.findIndex(elemento=>elemento.idpagocategoria  == id);
            state.categorias.splice(indice, 1)
        },
        pushCategoria(state,data){
             //Buscamos el indice por su id
             const indice = state.categorias.findIndex(elemento=>elemento.idpagocategoria  == data.idpagocategoria );
             if (indice > -1){
                //Asignamos la data en el indice encontrado
                Object.assign(state.categorias[indice], data)
             }else{
                 //Sino existe el indice insertamos el item
                 state.categorias.push(data)
             }
             
        }
    },
    actions:{
        async getCategorias({commit,getters}){
            try {
                const {data} = await axios.get(`${getters.apiUrl}/pagocategorias`,{headers:{'auth-user': getters.getToken}})
                commit("setCategorias",data)
            } catch (error) {
                commit("setCategorias",[])
                console.error(error)
            }
        },
        async postCategoria({commit,getters},item){
            item.idpersona = getters.getIdPersona;
            try {
                const {data}=await axios.post(`${getters.apiUrl}/pagocategorias`,item)
                commit("pushCategoria",data)
                swalOk()
            } catch (error) {
                console.error(error)
                swalError('No pudo crearse el registro')
            }
        }, 
        async putCategoria({commit,getters},item){
            try {
              const {data} = await axios.put(`${getters.apiUrl}/pagocategorias/${item.idpagocategoria }`,item)
              commit("pushCategoria",data)
              swalOk()
            } catch (error) {
                console.error(error)
                swalError('No pudo actualizar el registro')
            }
        },
        async deleteCategoria({commit,getters},id){
            const data = {estado:'Eliminado'}
            console.log(id)
            try {
                await axios.put(`${getters.apiUrl}/pagocategorias/${id}`,data)
                commit("deleteCategoria",id)
                swalOk()
            } catch (error) {
                console.error(error)
                swalError('No pudo eliminarse el registro')
            }
        }
    },
    getters: {
        getCategorias(state){
            return state.categorias.filter(item=>item.tipo=='Ingreso');
        },
        getCategoriasEgresos(state){
            return state.categorias.filter(item=>item.tipo=='Egreso');
        },
    }
}

function swalOk(){
    Swal.fire({       
        icon: 'success',
        title: '¡Realizado!',
        showConfirmButton: false,
        timer: 1500
    })
}

function swalError(message){
    Swal.fire({       
        icon: 'error',
        title: 'Error',
        text:message
    })
}