import Vue from 'vue'
import Vuex from 'vuex'
import Persona from '../components/persona/persona.vuex'
import Claves from '@/components/claves/claves.vuex'
import Contacto from '@/components/contacto/contacto.vuex'
import Anotaciones from '@/components/anotaciones/anotaciones.vuex'
import PagoCategoria from '@/components/categorias/categorias.vuex'
import PagoMedio from '@/components/mediospago/mediopago.vuex'
import Pago from '@/components/pagos/pago.vuex'
import PagoDetalles from '@/components/pagodetalle/pagodetalle.vuex'
import Login from '@/components/login/login.vuex'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    prodUrl:'https://declaraciones.microsystems.com.gt', 
    apiUrl:'https://declaracionesapi.up.railway.app/api/v1'
    //https://declaraciones.microsystems.com.gt
    //http://[::1]:1990
  },
  mutations: {
  },
  actions: {
  },
  getters:{
    apiUrl(state){
      return state.apiUrl
    }
  },
  modules: {
    Persona,Claves,Contacto,Anotaciones,
    PagoCategoria,PagoMedio,Pago,PagoDetalles,
    Login
  }
})
