var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.getClaves,"search":_vm.search},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_c('h3',[_vm._v(_vm._s(_vm.title))])]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2",attrs:{"color":"teal","dark":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-lock-plus")])],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.formTitle))])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('v-text-field',{attrs:{"hint":"Ingrese el usuario","color":"teal","label":"Usuario"},model:{value:(_vm.editedItem.usuario),callback:function ($$v) {_vm.$set(_vm.editedItem, "usuario", $$v)},expression:"editedItem.usuario"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('v-text-field',{attrs:{"append-icon":_vm.showClave2 ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.showClave2 ? 'text' : 'password',"hint":"Ingrese la clave","color":"teal","label":"Clave"},on:{"click:append":function($event){_vm.showClave2 = !_vm.showClave2}},model:{value:(_vm.editedItem.clave),callback:function ($$v) {_vm.$set(_vm.editedItem, "clave", $$v)},expression:"editedItem.clave"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"hint":"Ejemplo: Clave SAT","color":"teal","label":"Descripción"},model:{value:(_vm.editedItem.descripcion),callback:function ($$v) {_vm.$set(_vm.editedItem, "descripcion", $$v)},expression:"editedItem.descripcion"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"red","dark":""},on:{"click":_vm.close}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"color":"teal","dark":""},on:{"click":_vm.save}},[_vm._v(" Guardar ")])],1)],1)],1)],1)]},proxy:true},{key:"item.usuario",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{directives:[{name:"clipboard",rawName:"v-clipboard:copy",value:(item.usuario),expression:"item.usuario",arg:"copy"}],staticClass:"ma-3",attrs:{"solo":"","dense":"","append-icon":"mdi-content-copy","value":item.usuario},on:{"click":function($event){_vm.notificacion = true}}})]}},{key:"item.clave",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{directives:[{name:"clipboard",rawName:"v-clipboard:copy",value:(item.clave),expression:"item.clave",arg:"copy"}],staticClass:"ma-3",attrs:{"solo":"","dense":"","append-icon":_vm.showClave ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.showClave ? 'text' : 'password',"name":"input-10-1","value":item.clave},on:{"click:append":function($event){_vm.showClave = !_vm.showClave},"click":function($event){_vm.notificacion = true}}})]}},{key:"item.descripcion",fn:function(ref){
var item = ref.item;
return [_c('v-textarea',{attrs:{"dense":"","solo":"","value":item.descripcion,"rows":"1"}})]}},{key:"item.opciones",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","color":"blue"},on:{"click":function($event){return _vm.editItem(item)}}},[_c('v-icon',[_vm._v("mdi-lead-pencil")])],1),_c('v-btn',{attrs:{"icon":"","color":"red"},on:{"click":function($event){return _vm.deleteSwal(item)}}},[_c('v-icon',[_vm._v("mdi-trash-can")])],1)]}}],null,true)}),_c('v-snackbar',{attrs:{"timeout":_vm.timeout,"color":"amber accent-4","dark":""},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"blue-grey darken-4","text":""},on:{"click":function($event){_vm.notificacion = false}}},'v-btn',attrs,false),[_vm._v(" X ")])]}}]),model:{value:(_vm.notificacion),callback:function ($$v) {_vm.notificacion=$$v},expression:"notificacion"}},[_c('h4',[_vm._v("¡ Copiado !")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }