import axios from 'axios'
export default {

    state: {
        listpersona:[],
        idpersona:0,
    },
    mutations: {
        setPersona(state,data){
            state.listpersona=data;
        },
        savePersona(state,data){
            state.listpersona.push(data)
        },
        setIdPersona(state,id){state.idpersona = id},
        updatePersona(state,data){
            //Buscamos el indice por su id
            const indice = state.listpersona.findIndex(elemento=>elemento.idpersona == data.idpersona);
            //Asignamos la data en el indice encontrado
            Object.assign(state.listpersona[indice], data)
        },
        deletePersona(state,data){
            //Buscamos el indice por su id
            const indice = state.listpersona.findIndex(elemento=>elemento.idpersona == data.idpersona);
            //Eliminamos el indice encontrado
            state.listpersona.splice(indice, 1);
        }
    },
    actions: {
        async getAllPersonas({commit,rootState}){
            try {
                const response = await axios.get(`${rootState.apiUrl}/personas`);
                commit('setPersona',response.data)
              } catch (error) {
                console.error(error);
              }
        },
        setIdPersona({commit},id){
            commit("setIdPersona",id)
        },
        //Guardar Registro
        savePersona({commit},data){ commit('savePersona',data) },
        //Actualizar Registro
        updatePersona({commit},data){ commit('updatePersona',data) },
        //Eliminar Registro
        deletePersona({commit},data){ commit('deletePersona',data) },
    },
    getters:{
        getPersonas(state){
            return state.listpersona
        },
        getIdPersona(state){
            return state.idpersona
        }
    }
}