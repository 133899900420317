<template>
  <v-card>
    <v-data-table
      :headers="headers"
      :items="getMediosPagos"
      :search="search"
    >
    <template v-slot:top>
      <v-toolbar
        flat
      >
        <v-toolbar-title> <h3>{{title}}</h3> </v-toolbar-title>
        <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>
        <v-spacer></v-spacer>
        <v-dialog
          v-model="dialog"
          max-width="500px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="teal" dark class="mb-2" v-bind="attrs" v-on="on">
               <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">{{formTitle}}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-text-field v-model="editedItem.nombre" hint="Ejemplo: Efectivo" color="teal" label="Medio de Pago"></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="red"
                dark
                @click="close"
              >
                Cancelar
              </v-btn>
              <v-btn
                color="teal"
                dark
                @click="save"
              >
                Guardar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
     <template v-slot:[`item.opciones`]="{ item }">
      <v-btn icon color="blue" @click="editItem(item)">
        <v-icon>mdi-lead-pencil</v-icon>
      </v-btn>
      <v-btn icon color="red" @click="deleteSwal(item)">
        <v-icon>mdi-trash-can</v-icon>
      </v-btn>
    </template>
    </v-data-table>
  </v-card>
</template>
<script>
import Swal from 'sweetalert2'

export default {
    data: () => ({
        dialog:false,
        search:'',
        title:'MEDIOS DE PAGO',
        headers:[
            { text: 'Medio de Pago', value: 'nombre' },
            { text: 'Opciones', value: 'opciones' }
        ],
        editedIndex: -1,
        editedItem: {
            nombre:''
        },
        defaultItem: {
            nombre:''
        }
    }),
  created(){
      const id = parseInt(  localStorage.getItem('idpersona') ) 
      this.$store.dispatch("setIdPersona",id)
      this.$store.dispatch("getMediosPagos")
  }, 
    computed:{
        getMediosPagos(){
        return this.$store.getters.getMediosPagos;
        },
        formTitle() {
            return this.editedIndex === -1 ? 'Nuevo Registro' : 'Editar Registro';
        },
    },
    methods:{
      async getItems(){
            await this.$store.dispatch("getMediosPagos");
      },
      async postItem(){
        
        await  this.$store.dispatch("postMedioPago",this.editedItem);
      },
      async putItem(){
          await this.$store.dispatch("putMedioPago",this.editedItem);
      },
      editItem(item) {   
          this.dialog = true; 
          this.editedIndex = this.getMediosPagos.indexOf(item)       
          this.editedItem = Object.assign({}, item)
      },
      async deleteItem(id){
          await this.$store.dispatch("deleteMedioPago",id);
      },
     save () {

          if (this.editedIndex > -1) {
                this.putItem();
          } else {
               this.postItem(); 
          }
          this.close()
     },
     async close(){  
         this.dialog = false;
         this.$nextTick(() => {
            this.editedItem = Object.assign({}, this.defaultItem);
            this.editedIndex = -1
          })
     },
     deleteSwal(item){
        Swal.fire({
        title: 'Presione Sí para eliminar',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText:'Cancelar',
        confirmButtonText: 'Sí'
        }).then((result) => {
        if (result.isConfirmed) {
            this.deleteItem(item.idpagomedio);
        }
        })
    },
  }
}
</script>