import axios from 'axios'
import Swal from 'sweetalert2'

export default {
    state:{
        mediospagos:[],
    },
    mutations:{
        setMediosPagos(state,data){ state.mediospagos=data},

        deleteMedioPago(state,id){
            const indice = state.mediospagos.findIndex(elemento=>elemento.idpagomedio  == id);
            state.mediospagos.splice(indice, 1)
        },
        pushMedioPago(state,data){
             //Buscamos el indice por su id
             const indice = state.mediospagos.findIndex(elemento=>elemento.idpagomedio  == data.idpagomedio );
             if (indice > -1){
                //Asignamos la data en el indice encontrado
                Object.assign(state.mediospagos[indice], data)
             }else{
                 //Sino existe el indice insertamos el item
                 state.mediospagos.push(data)
             }
             
        }
    },
    actions:{
        async getMediosPagos({commit,getters}){
            try {
                const {data} = await axios.get(`${getters.apiUrl}/pagomedios`,{headers:{'auth-user': getters.getToken}})
                commit("setMediosPagos",data)
            } catch (error) {
                commit("setMediosPagos",[])
                console.error(error)
            }
        },
        async postMedioPago({commit,getters},item){
            
            try {
                const {data}=await axios.post(`${getters.apiUrl}/pagomedios`,item)
                commit("pushMedioPago",data)
                swalOk()
            } catch (error) {
                console.error(error)
                swalError('No pudo crearse el registro')
            }
        }, 
        async putMedioPago({commit,getters},item){
            try {
              const {data} = await axios.put(`${getters.apiUrl}/pagomedios/${item.idpagomedio }`,item)
              commit("pushMedioPago",data)
              swalOk()
            } catch (error) {
                console.error(error)
                swalError('No pudo actualizar el registro')
            }
        },
        async deleteMedioPago({commit,getters},id){
            try {
                await axios.delete(`${getters.apiUrl}/pagomedios/${id}`)
                commit("deleteMedioPago",id)
                swalOk()
            } catch (error) {
                console.error(error)
                swalError('No pudo eliminarse el registro')
            }
        }
    },
    getters: {
        getMediosPagos(state){
            return state.mediospagos;
        },
    }
}

function swalOk(){
    Swal.fire({       
        icon: 'success',
        title: '¡Realizado!',
        showConfirmButton: false,
        timer: 1500
    })
}

function swalError(message){
    Swal.fire({       
        icon: 'error',
        title: 'Error',
        text:message
    })
}