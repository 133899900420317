<template>
  <v-card>
    <v-data-table
      :headers="headers"
      :items="getClaves"
      :search="search"
    >
    <template v-slot:top>
      <v-toolbar
        flat
      >
        <v-toolbar-title><h3>{{title}}</h3></v-toolbar-title>
        <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>
        <v-spacer></v-spacer>
        <v-dialog
          v-model="dialog"
          max-width="500px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="teal" dark class="mb-2" v-bind="attrs" v-on="on">
              <v-icon>mdi-lock-plus</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">{{formTitle}}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field v-model="editedItem.usuario" hint="Ingrese el usuario" color="teal" label="Usuario"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                    v-model="editedItem.clave" 
                    :append-icon="showClave2 ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="showClave2 ? 'text' : 'password'"
                    @click:append="showClave2 = !showClave2"
                    hint="Ingrese la clave" 
                    color="teal" 
                    label="Clave"></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field v-model="editedItem.descripcion" hint="Ejemplo: Clave SAT" color="teal" label="Descripción"></v-text-field>     
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="red"
                dark
                @click="close"
              >
                Cancelar
              </v-btn>
              <v-btn
                color="teal"
                dark
                @click="save"
              >
                Guardar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:[`item.usuario`]="{ item }">
      <v-text-field
            solo
            dense
            append-icon="mdi-content-copy"
            :value="item.usuario"
            class="ma-3"
            v-clipboard:copy="item.usuario"
            @click="notificacion = true"
          ></v-text-field>
    </template>
    <template v-slot:[`item.clave`]="{ item }">
      <v-text-field
            solo
            dense
            :append-icon="showClave ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showClave ? 'text' : 'password'"
            name="input-10-1"
            :value="item.clave"
            class="ma-3"
            v-clipboard:copy="item.clave"
            @click:append="showClave = !showClave"
            @click="notificacion = true"
          ></v-text-field>
    </template>
    <template v-slot:[`item.descripcion`]="{ item }">
      <v-textarea dense solo :value="item.descripcion" rows="1"></v-textarea>
    </template>
     <template v-slot:[`item.opciones`]="{ item }">
      <v-btn icon color="blue" @click="editItem(item)">
        <v-icon>mdi-lead-pencil</v-icon>
      </v-btn>
      <v-btn icon color="red" @click="deleteSwal(item)">
        <v-icon>mdi-trash-can</v-icon>
      </v-btn>
    </template>
    </v-data-table>
    <v-snackbar
      v-model="notificacion"
      :timeout="timeout"
      color="amber accent-4"
      dark
    >
        <h4>¡ Copiado !</h4>

      <template v-slot:action="{ attrs }">
        <v-btn color="blue-grey darken-4" text v-bind="attrs" @click="notificacion = false"> X </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>
<script>
import Swal from 'sweetalert2'
import dayjs from 'dayjs'

export default {
    data: () => ({
        notificacion:false,
        dialog:false,
        showClave:false,
        showClave2:false,
        timeout:2000,
        search:'',
        title:'CLAVES',
        headers:[
            { text: 'Usuario', value: 'usuario' },
            { text: 'Clave', value: 'clave' },
            { text: 'Descripción', value: 'descripcion' },
            { text: 'Opciones', value: 'opciones' }
        ],
        editedIndex: -1,
        editedItem: {
            usuario:'',
            clave:'',
            descripcion:'',
            fechaactualizacion:dayjs().format('YYYY-MM-DD'),
            idpersona:1
        },
        defaultItem: {
            usuario:'',
            clave:'',
            descripcion:'',
            fechaactualizacion:dayjs().format('YYYY-MM-DD'),
            idpersona:1
        }
    }),
  created(){
      const id = parseInt(  localStorage.getItem('idpersona') ) 
      this.$store.dispatch("setIdPersona",id)
      this.$store.dispatch("getClaves")
  }, 
    computed:{
        getClaves(){
        return this.$store.getters.getClaves;
        },
        formTitle() {
            return this.editedIndex === -1 ? 'Nuevo Registro' : 'Editar Registro';
        },
    },
    methods:{
      async getItems(){
            await this.$store.dispatch("getClaves");
      },
      async postItem(){
        
        await  this.$store.dispatch("postClaves",this.editedItem);
      },
      async putItem(){
          await this.$store.dispatch("putClaves",this.editedItem);
      },
      editItem(item) {   
          this.dialog = true; 
          this.editedIndex = this.getClaves.indexOf(item)       
          this.editedItem = Object.assign({}, item)
      },
      async deleteItem(id){
          await this.$store.dispatch("deleteClaves",id);
      },
     save () {
         this.editedItem.fechaactualizacion=dayjs().format('YYYY-MM-DD')
          if (this.editedIndex > -1) {
                this.putItem();
          } else {
               this.postItem(); 
          }
          this.close()
     },
     async close(){  
         this.dialog = false;
         this.$nextTick(() => {
            this.editedItem = Object.assign({}, this.defaultItem);
            this.editedIndex = -1
          })
     },
     deleteSwal(item){
        Swal.fire({
        title: 'Presione Sí para eliminar',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText:'Cancelar',
        confirmButtonText: 'Sí'
        }).then((result) => {
        if (result.isConfirmed) {
            this.deleteItem(item.idclave);
        }
        })
    },
  },
  filters: {
    formatoFechas: function (value) {
        if (!value) return ''
        //value = value.toString()
        return dayjs(value).format('DD-MM-YYYY') //value.charAt(0).toUpperCase() + value.slice(1)
    }
  }
}
</script>