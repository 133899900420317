import axios from 'axios'
import Swal from 'sweetalert2'

export default {
    state:{
        anotaciones:[],
    },
    mutations:{
        setAnotaciones(state,data){ state.anotaciones=data},

        deleteAnotacion(state,id){
            const indice = state.anotaciones.findIndex(elemento=>elemento.idanotacion == id);
            state.anotaciones.splice(indice, 1)
        },
        pushAnotacion(state,data){
             //Buscamos el indice por su id
             const indice = state.anotaciones.findIndex(elemento=>elemento.idanotacion == data.idanotacion);
             if (indice > -1){
                //Asignamos la data en el indice encontrado
                Object.assign(state.anotaciones[indice], data)
             }else{
                 //Sino existe el indice insertamos el item
                 state.anotaciones.push(data)
             }
             
        }
    },
    actions:{
        async getAnotaciones({commit,getters}){
            try {
                const {data} = await axios.get(`${getters.apiUrl}/anotaciones/${getters.getIdPersona}`)
                commit("setAnotaciones",data)
            } catch (error) {
                commit("setAnotaciones",[])
                console.error(error)
            }
        },
        async postAnotacion({commit,getters},item){
            item.idpersona = getters.getIdPersona;
            try {
                const {data}=await axios.post(`${getters.apiUrl}/anotaciones`,item)
                commit("pushAnotacion",data)
                swalOk()
            } catch (error) {
                console.error(error)
                swalError('No pudo crearse el registro')
            }
        }, 
        async putAnotacion({commit,getters},item){
            
            try {
              const {data} = await axios.put(`${getters.apiUrl}/anotaciones/${item.idanotacion}`,item)
              commit("pushAnotacion",data)
              swalOk()
            } catch (error) {
                console.error(error)
                swalError('No pudo actualizar el registro')
            }
        },
        async deleteAnotacion({commit,getters},id){
            try {
                await axios.delete(`${getters.apiUrl}/anotaciones/${id}`)
                commit("deleteAnotacion",id)
                swalOk()
            } catch (error) {
                console.error(error)
                swalError('No pudo eliminarse el registro')
            }
        }
    },
    getters: {
        getAnotaciones(state){
            return state.anotaciones;
        },
    }
}

function swalOk(){
    Swal.fire({       
        icon: 'success',
        title: '¡Realizado!',
        showConfirmButton: false,
        timer: 1500
    })
}

function swalError(message){
    Swal.fire({       
        icon: 'error',
        title: 'Error',
        text:message
    })
}